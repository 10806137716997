import React from 'react';

import ResponsiveRender from 'components/ResponsiveRender';
import Modal from 'components/Modal';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  // target the modal window
  > [class$='__content'] {
    border-radius: 15px;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
`;
export default function ResponsiveModal(props) {
  return (
    <ResponsiveRender>
      {isMobile => (
        <ModalStyled isFullScreen={isMobile} {...props}>
          {props.children}
        </ModalStyled>
      )}
    </ResponsiveRender>
  );
}
