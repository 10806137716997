import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const HalfDayMarker = styled.div`

  /* .CalendarDay__blocked_out_of_range {
    background: #fff !important;
    border: 1px solid #e4e7e7 !important;
    color: #cacccd !important;
  } */

  ${props => (props.startOrEnd === 'both') &&
    `background-color: #cacccd;`
  }
  ${props => (props.startOrEnd === 'start') &&
    `
    background: rgba(248, 80, 50, 1);
    background:linear-gradient(to top left,#cacccd 50%,transparent 50%);
    `
  }
  ${props => (props.startOrEnd === 'end') &&
    `
    background: rgba(248, 80, 50, 1);
    background:linear-gradient(to bottom right,#cacccd 50%,transparent 50%);
    `
  }
  display: inline-block;
  height: 38px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 39px;
`;

const DateWrapper = styled.span`
  z-index: 1;
  position: relative;
  top: 25%;
`;

const HalfBlockedCalendarDay = ({ children, startOrEnd }) => {
  return(
    <Wrapper>
      <DateWrapper>
        {children}
      </DateWrapper>
      <HalfDayMarker startOrEnd={startOrEnd} />
    </Wrapper>
  )
}

export default HalfBlockedCalendarDay;
