import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import { Button } from 'core/ui';
import ResponsiveModal from 'components/ResponsiveModal';
import { ModalHeader, ModalHeading, CloseButton } from 'components/Modal';
import * as Dates from 'core/helpers/Dates';

import HalfBlockedCalendarDay from 'components/CalendarPartialBlocks/HalfBlockedCalendarDay';

import {
  DateRangePickerInputController,
  DayPickerRangeController,
  START_DATE,
  END_DATE,
} from 'components/DatePicker';

import './DatePickerModal.scss';

const BodyStyled = styled.div`
  flex-direction: column;
  display: flex;
  position: relative;
  flex: 1 1 auto;
  height: auto;
  min-height: 100%;
  z-index: 50;

  .DateRangePickerInput {
    margin: 15px;
  }

  .DayPicker_weekHeader_li {
    padding-bottom: 5px;
    padding-top: 4px;
  }

  .CalendarDay__selected.CalendarDay__selected_start {
    ${({ sameDayTurnaround }) =>
      sameDayTurnaround &&
      `background: linear-gradient(
            to bottom right,
            transparent 0%,
            transparent 50%,
            #0d63a6 50%,
            #0d63a6 100%
          );
          border: none;
          color: #073458;
          `}
  }

  .CalendarDay__selected.CalendarDay__selected_end {
    ${({ sameDayTurnaround }) =>
      sameDayTurnaround &&
      `background: linear-gradient(
            to bottom right,
            #0d63a6 0%,
            #0d63a6 50%,
            transparent 50%,
            transparent 100%
          );
          border: none;
          color: #073458;
          `}
  }
`;

const Calendars = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  min-height: 375px;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
`;

const ClearLabel = styled.span`
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-size: 13px;
  font-weight: normal;
`;

const StyledButton = styled(Button)`
  height: 50px;
  font-size: 18px;
`;

const beginFence = moment()
  .startOf('day')
  .subtract(1, 'day');

const endFence = moment()
  .add(24, 'months')
  .startOf('day');

const defaultIsOutsideRange = date => Dates.isInclusivelyOutside(date, beginFence, endFence);

class DatePickerModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: START_DATE,
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  // Force focus
  handleFocusChange = focusedInput => this.setState({ focusedInput: focusedInput || START_DATE });

  handleDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  handleSubmit = () => {
    this.props.onSubmit({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });

    this.props.modal.onRequestClose();
  };

  handleClose = () => {
    this.props.modal.onRequestClose();
  };

  // Make sure we start on the current month
  initialVisibleMonth = () => moment();

  renderDatePickerInput = () => {
    const { startDate, endDate, focusedInput } = this.state;

    return (
      <DateRangePickerInputController
        startDate={startDate}
        startDateId="start_date"
        endDate={endDate}
        endDateId="end_date"
        startDatePlaceholderText="Pickup"
        endDatePlaceholderText="Dropoff"
        isStartDateFocused={focusedInput === START_DATE}
        isEndDateFocused={focusedInput === END_DATE}
        onDatesChange={this.handleDatesChange}
        onFocusChange={this.handleFocusChange}
        readOnly
        block
        showClearDates
        reopenPickerOnClearDates
        customCloseIcon={<ClearLabel>Clear</ClearLabel>}
      />
    );
  };

  renderDatePicker = () => {
    const { startDate, endDate, focusedInput } = this.state;

    const {
      isDayBlockedFactory,
      isOutsideRange,
      isStartDatePartiallyBlockedFactory,
      isEndDatePartiallyBlockedFactory,
      sameDayTurnaround,
    } = this.props;

    const renderPartiallyBlockedDays = date => {
      const partiallyBlockedStart = isStartDatePartiallyBlockedFactory(date);
      const partiallyBlockedEnd = isEndDatePartiallyBlockedFactory(date);

      if (partiallyBlockedStart && partiallyBlockedEnd) {
        return (
          <HalfBlockedCalendarDay startOrEnd="both">{date.format('D')}</HalfBlockedCalendarDay>
        );
      }
      if (partiallyBlockedStart) {
        return (
          <HalfBlockedCalendarDay startOrEnd="start">{date.format('D')}</HalfBlockedCalendarDay>
        );
      }
      if (partiallyBlockedEnd) {
        return <HalfBlockedCalendarDay startOrEnd="end">{date.format('D')}</HalfBlockedCalendarDay>;
      }

      return date.format('D');
    };

    return (
      <DayPickerRangeController
        renderDayContents={date =>
          sameDayTurnaround ? renderPartiallyBlockedDays(date) : date.format('D')
        }
        startDate={startDate}
        endDate={endDate}
        focusedInput={focusedInput}
        onDatesChange={this.handleDatesChange}
        onFocusChange={this.handleFocusChange}
        orientation="verticalScrollable"
        numberOfMonths={12}
        hideKeyboardShortcutsPanel
        enableOutsideDays
        initialVisibleMonth={this.initialVisibleMonth}
        isDayBlocked={isDayBlockedFactory && isDayBlockedFactory(startDate, focusedInput)}
        isOutsideRange={isOutsideRange || defaultIsOutsideRange}
      />
    );
  };

  render() {
    const { modal, buttonText, buttonVariant, sameDayTurnaround } = this.props;

    return (
      <ResponsiveModal {...modal} id="date-picker-modal" className="date-picker-modal">
        <ModalHeader>
          <ModalHeading>Enter Dates</ModalHeading>
          <CloseButton type="button" onClick={this.handleClose} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </CloseButton>
        </ModalHeader>

        <BodyStyled className="rvshare-DatePicker" sameDayTurnaround={sameDayTurnaround}>
          {this.renderDatePickerInput()}
          <Calendars>{this.renderDatePicker()}</Calendars>
        </BodyStyled>

        <ModalFooter>
          <StyledButton
            type="button"
            variant={buttonVariant || 'blueLarge'}
            onClick={this.handleSubmit}
          >
            {buttonText || 'Select Dates'}
          </StyledButton>
        </ModalFooter>
      </ResponsiveModal>
    );
  }
}

DatePickerModal.propTypes = {
  modal: PropTypes.shape({
    toggle: PropTypes.func.isRequired,
  }).isRequired,
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  isDayBlockedFactory: PropTypes.func,
  isOutsideRange: PropTypes.func,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string,
};

DatePickerModal.defaultProps = {
  startDate: null,
  endDate: null,
  buttonText: '',
  buttonVariant: null,
  isDayBlockedFactory: undefined,
  isOutsideRange: undefined,
};

export default DatePickerModal;
