import React from 'react';
import Media from 'react-media';

import { withDeviceDetection } from 'core/state/Globals';

function ResponsiveRender({ device, maxWidth = '767px', children, ...props }) {
  return (
    <Media query={`(max-width: ${maxWidth})`} defaultMatches={device.isMobile} {...props}>
      {isMobile => children(isMobile)}
    </Media>
  );
}

export default withDeviceDetection(ResponsiveRender);
